<template>
  <div>404</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 500px;
  color: red;
}
</style>